<template>
  <div :class="`membership-selection ${$isMobile ? 'is-mobile' : ''}`">
    <NavBack :action="goBack"/>
    <div class="sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div :class="`membership-content is-relative pt-4 pb-1 ${showAccreditedInvestorOption ? '' : 'two-options'}`">
      <div class="modal-title has-text-weight-semibold has-text-centered">Become a paid member today!</div>
      <div class="descriptions" v-if="showAccreditedInvestorOption">
        <div
          v-for="(item, index) in membershipDescriptionsWithAccredited"
          :key="index"
          class="is-flex is-align-items-flex-start description"
        >
          <img src="../../assets/icons/check.svg" alt="check-icon" class="check-icon">
          <span class="text">{{ item }}</span>
        </div>
      </div>
      <div class="descriptions-wrap is-flex has-flex-direction-column" v-else>
        <div
          v-for="(membershipDescriptions, membershipType) in membershipDescriptionsMap"
          :key="membershipType"
          :class="`descriptions ${membershipType}-membership`"
        >
          <div
            v-for="(description, index) in membershipDescriptions"
            :key="index"
            class="is-flex is-align-items-flex-start description"
          >
            <img src="../../assets/icons/check.svg" alt="check-icon" class="check-icon">
            <span class="text">{{ description }}</span>
          </div>
        </div>
      </div>
      <div class="options is-flex is-justify-content-space-between">
        <div class="option is-relative free-member">
          <div class="frame has-background-blue has-text-white has-text-centered" v-show="selectedType === 'free'">OWNED</div>
          <div class="selection-wrap is-flex is-justify-content-center is-align-items-flex-end">
            <div
              :class="`selection-box has-background-white is-clickable ${selectedType === 'free' ? '' : 'has-light-border'}`"
              @click="selectedType = 'free'"
            >
              <div class="option-title has-text-centered has-text-weight-bold">
                <div class="has-text-blue">FREE</div>
                <div class="has-text-blue">MEMBER</div>
              </div>
              <div class="option-cost has-text-centered">
                <div>$0</div>
              </div>
            </div>
          </div>
        </div>
        <div class="option is-relative addyOne-member">
          <div class="frame has-background-blue has-text-white has-text-centered" v-show="selectedType === 'addyOne'">SELECTED</div>
          <div :class="`selection-wrap is-flex is-justify-content-center is-align-items-flex-end ${selectedType === 'addyOne' ? '' : 'less-padding-bottom'}`">
            <div
              :class="`selection-box has-background-white is-clickable ${selectedType === 'addyOne' ? '' : 'has-light-border'}`"
              @click="selectedType = 'addyOne'"
            >
              <div class="option-title has-text-centered has-text-weight-bold">
                <div class="has-text-blue">addyONE</div>
                <div class="has-text-blue">MEMBER</div>
              </div>
              <div class="option-cost has-text-centered">
                <div>$50 + tax</div>
                <div>yearly</div>
              </div>
            </div>
          </div>
        </div>
        <div data-testid="accredited-option" v-if="showAccreditedInvestorOption" class="option is-relative accredited">
          <div class="frame has-background-blue has-text-white has-text-centered" v-show="selectedType === 'accredited'">SELECTED</div>
          <div :class="`selection-wrap is-flex is-justify-content-center is-align-items-flex-end ${selectedType === 'accredited' ? '' : 'less-padding-bottom'}`">
            <div
              :class="`selection-box has-background-white is-clickable ${selectedType === 'accredited' ? '' : 'has-light-border'}`"
              @click="selectedType = 'accredited'"
            >
              <div class="option-title has-text-centered has-text-weight-bold">
                <div class="has-text-blue">ACCREDITED</div>
                <div class="has-text-blue">MEMBER</div>
              </div>
              <div class="option-cost has-text-centered">
                <div>$500 + tax</div>
                <div>yearly</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="bottom">
        <BottomButtons
          nextButtonText="Continue"
          backButtonText="Back"
          :nextDisabled="selectedType === 'free'"
          :showSteps="false"
          @goNext="toNextPage"
          @goBack="goBack"
        ></BottomButtons>
      </section>
    </div>
  </div>
</template>

<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import NavBack from '@components/desktop/header-nav-back.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { mapState } from 'vuex'
import { getFeatureToggle } from '@api/common'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  data() {
    return {
      selectedType: 'addyOne',
      accreditedToggleOn: false,
      useStripeForPayment: false,
    }
  },
  components: {
    MenuAndLogo,
    NavBack,
    BottomButtons,
  },
  created() {
    this.getToggle()
    this.fetchStripeToggle()
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
    membershipDetails() {
      return JSON.parse(localStorage.getItem('membershipDetails'))
    },
    nextStepUrl() {
      const url = this.$route.query.nextStepUrl
      return url ? decodeURIComponent(url) : undefined
    },
    returnUrl() {
      return this.$route.query.returnUrl || encodeURIComponent('/dashboard')
    },
    membershipDescriptionsWithAccredited() {
      if (this.selectedType === 'accredited') {
        return [
          'All addyOne member benefits',
          'Make a meaningful impact! Many of the deals we look at usually have minimum investment requirements that are higher than the buying power of the addy crowd. 😮 Partnering with addy Accredited members allows us to top up an issuance so that we can close on the deal and make it available for everyone 😍',
          `Invest higher than the $${this.membershipDetails.addyOne.purchaseLimit} investment cap`,
          `100% moneyback guarantee if you’re unsatisfied with your membership!`,
        ]
      } else {
        return this.membershipDescriptionsMap[this.selectedType]
      }
    },
    membershipDescriptionsMap() {
      return {
        free: [
          'Access to most addy properties',
          'Invest from 1 to $1,500 per property',
          'No access to Instant Funding',
          'Investment opens 3 days after initial drop',
          'Read-only access to community Discord',
        ],
        addyOne: [
          'Access to ALL properties',
          'Invest from 1 to $2,500 per property',
          'Access to the “addyverse” portfolio display',
          '$2,500 in Instant Funding',
          'Access to learning community on Discord',
          'Invite-only opportunities and live events',
          '60-day money back guarantee on your membership fee',
          'Priority access to investments with no exclusions',
        ]
      }
    },
    membershipTypeInPropBuy() {
      const map = {
        addyOne: 'addyone_membership',
        accredited: 'accredited_membership',
      }
      return map[this.selectedType]
    },
    showAccreditedInvestorOption() {
      return this.accreditedToggleOn
    },
  },
  methods: {
    getToggle() {
      getFeatureToggle('accredited_investor_onboarding').then((data) => {
        if (!data.success) return
        this.accreditedToggleOn = data.data.enabled
      })
    },
    fetchStripeToggle() {
      getFeatureToggle('stripe_membership_payment').then((data) => {
        if (!data.success) return
        this.useStripeForPayment = data.data.enabled
      })
    },
    toNextPage() {
      const selectedAccredited = this.selectedType === 'accredited'

      if (this.nextStepUrl) { // in the property purchase flow
        if (selectedAccredited) this.toAccreditedParagraphThenResidency()
        else this.toResidencyPage()
      } else if (selectedAccredited) this.toAccreditedParagraphPage()
      else this.toMembershipConfirmationPage()
    },
    toResidencyPage() {
      window.localStorage.setItem('membershipTypeInPropBuy', this.membershipTypeInPropBuy)
      this.$router.push(this.nextStepUrl)
    },
    toAccreditedParagraphThenResidency() {
      window.localStorage.setItem('membershipTypeInPropBuy', this.membershipTypeInPropBuy)
      this.$router.push(`/membership/accredited/paragraphs?nextStepUrl=${encodeURIComponent(this.nextStepUrl)}`)
    },
    toAccreditedParagraphPage() {
      this.$router.push(`/membership/accredited/paragraphs?returnUrl=${this.returnUrl}`)
    },
    toMembershipConfirmationPage() {
      const trackerHash = this.selectedType === 'addyOne' ? 'zxxq3bvp' : '36p7bd58'
      this.mixpanel.track(trackerHash)

      if (this.useStripeForPayment) this.$router.push(`/membership/stripeCheckout/${this.selectedType}?returnUrl=${this.returnUrl}`)
      // returnUrl is used to differentiate the membership buy flow started from the portfolio page and dashboard
      else this.$router.push(`/membership/confirmation/${this.selectedType}?returnUrl=${this.returnUrl}`)
    },
    goBack() {
      const returnUrl = this.$route.query.returnUrl
      if (embeddedInIos()) window.webkit.messageHandlers.closeWebview.postMessage(true)
      else if (returnUrl) this.$router.replace(decodeURIComponent(returnUrl))
      else this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
$background: #F0EEF8;

.membership-selection {
  background-color: $background;
  min-height: $min-height-mobile-container;
  max-width: $mobile-wrapper-width;
  border-radius: 12px;
  &.is-mobile {
    width: 100vw;
    min-height: 100vh;
    border-radius: unset;
    .membership-content {
      &.two-options {
        .descriptions {
          padding: unset;
        }
        .options {
          position: unset;
          top: unset;
          left: unset;
        }
        .bottom {
          position: unset;
          top: unset;
          left: unset;
        }
      }
      .options {
        position: absolute;
        top: 366px;
        left: 0;
        padding: 0 26px 60px;
      }
      .bottom {
        position: absolute;
        top: 550px;
        left: 0;
      }
    }
  }
  .membership-content {
    .modal-title {
      font-size: 24px;
    }
    .descriptions-wrap {
      padding: 20px 26px 0;
    }
    .descriptions {
      padding: 10px 19px 0 30px;
      .description {
        &:not(:last-of-type) {
          margin-bottom: 12px;
        }
        .check-icon {
          padding-right: 14px;
          padding-top: 4px;
        }
        .text {
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
    .options {
      width: 100%;
      padding: 60px 26px;
      background-color: $background;
      .option {
        flex: 1;
        height: 170px;
        &:not(:last-child) {
          margin-right: 6px;
        }
        .frame {
          width: 100%;
          height: 100%;
          border-radius: 14px;
          font-size: 14px;
          line-height: 17px;
          padding-top: 8px;
        }
        .selection-wrap {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding: 4px;
          &.less-padding-bottom {
            padding: 2px;
          }
          .selection-box {
            width: 100%;
            height: 134px;
            border-radius: 12px;
            box-sizing: content-box;
            &.has-light-border {
              border: 2px solid rgba(74, 38, 170, 0.25);
            }
            .option-title {
              font-size: 13px;
              line-height: 22px;
              padding-top: 14px;
              box-sizing: border-box;
              div {
                font-family: ProximaNova, sans-serif;
              }
            }
            .option-cost {
              padding-top: 26px;
              font-size: 16px;
              line-height: 19px;
              box-sizing: border-box;
            }
          }
        }
      }
    }
    .bottom {
      width: 100%;
      padding: 30px 27px 80px;
      background-color: $background;
      &.greater-top {
        top: 580px;
      }
    }
    &.two-options {
      .descriptions {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: unset;
        &:not(:last-child) {
            margin-right: 9px;
        }
      }
      .options {
        padding: 30px 26px 0;
        .option {
          height: 198px;
          &:not(:last-child) {
            margin-right: 9px;
          }
          .selection-box {
            height: 162px;
            .option-title {
              font-size: 18px;
            }
            .option-cost {
              font-size: 24px;
              line-height: 29px;
            }
          }
        }
      }
      .bottom {
        padding: 30px 27px;
      }
    }
  }
}
</style>
